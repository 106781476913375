<template>
  <div class="main">
    <div class="banner">
      <div class="demo-carousel">
        <div class="demo-carousel-item">
          <div class="carousel_content">
            <h2>专注食堂点餐解决方案</h2>
            <p>打造一体化食堂餐饮服务</p>
            <button>立即咨询</button>
          </div>
          <img
            class="demo-img"
            src="../../assets/image/banner_index1.png"
          >
        </div>
      </div>
    </div>
    <div class="advantage_wrap">
      <ul class="enterprise_con_ul">
        <li
          class="enterprise_con_li"
          v-for="item in enterpriseList"
          :key="item.title"
        >
          <div class="con_li_img">
            <img
              :src="item.serviceIcon"
              alt="#"
            >
          </div>
          <div class="con_li_content">
            <p class="con_li_title">{{item.title}}</p>
            <p class="con_li_text1">{{item.text1}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="case_wrap">
      <div class="case_con">
        <div class="case_con_img1">
          <img
            src="../../assets/image/appMall_restaurant01.png"
            alt=""
          >
        </div>
        <div class="case_con_info">
          <div>
            <h4>用心做好智慧餐饮</h4>
            <p>95%点餐系统力求简单实用，专为中小餐厅设计，简洁大方，容易上手。强大且灵活的功能，能够完美契合您的业务需求，做专业的智能助手。点餐系统借助领先流行的技术，让您享受专有企业稳定的网络支撑，轻松帮您实现餐饮门店的运营管理，这可能正好是您需要的贴心服务。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="promotion_wrap">
      <h3>核心功能，全场景的分析</h3>
      <div class="promotion_list">
        <li
          class="promotion_list_con"
          v-for="item in promotionList"
          :key="item.title"
        >
          <div class="promotion_list_imgbox">
            <img
              :src="item.icon"
              alt=""
            >
          </div>
          <h4>{{ item.title}}</h4>
        </li>
      </div>
    </div>
    <div class="">
      <div class="support_header_nav">
        <h2>软件支持</h2>
        <ul>
          <li
            @click=change(1)
            :class='currentIndex==1?"active":""'
          ><span>后台管理端功能</span></li>
          <li
            @click=change(2)
            :class='currentIndex==2?"active":""'
          ><span>在线预约点餐功能</span></li>
        </ul>
        <div
          class="backstage"
          v-show="currentIndex==1"
        >
          <div class="support_con">
            <div class="support_con_img">
              <img
                src="../../assets/image/appMall_restaurant02.png"
                alt=""
              >
            </div>
            <div class="support_con_info">
              <div>
                <p>桌面点餐端用于食堂固定自助点餐,医护职工在线自助选择自助结算,订单支付支持餐补余额支付支持饭卡支付.</p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="backstage"
          v-show="currentIndex==2"
        >
      
        </div>
      </div>
    </div>
    <div class="support_wrap">
      <h2>功能支持</h2>
      <ul class="support_con_ul">
        <li
          class="develop_con_li"
          v-for="item in developList"
          :key="item.title"
        >
          <div class="con_li_img">
            <img
              :src="item.serviceIcon"
              alt="#"
            >
          </div>
          <div class="develop_li_content">
            <p class="develop_li_title">{{item.title}}</p>
            <p class="develop_li_text">{{item.text}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="process_wrap">
      <h2>功能支持</h2>
      <div class="process_img">
        <img
          src="../../assets/image/process_img.png"
          alt=""
        >
      </div>
    </div>
    <div class="worth_wrap">
      <h2>核心价值，需求与挑战</h2>
      <div class="worth_content">
        <div class="worth_left">
          <ul>
            <li class="worth_describe">
              <h4>点餐便捷高效</h4>
              <p>线上线下多种模式预定点餐，在线支付，刷卡支付，余额支付，多方式支付结算，订单明细手机查看小票打印，信息完全透明化</p>
            </li>
            <li class="worth_describe">
              <h4>效率显著提升</h4>
              <p>预定点餐提前预定结算，食堂现场现点现取，提升结算效率</p>
            </li>
            <li class="worth_describe">
              <h4>有效把控运营</h4>
              <p>大数据订餐智能表单，提前汇总点餐数量，便采购有据可依，人力合理分配，降低运营成本，提升盈利</p>
            </li>
            <li class="worth_describe">
              <h4>信息化管理降本增效</h4>
              <p>后台大数据精细化管理，经营大数据提供决策支持</p>
            </li>
          </ul>
        </div>
        <div class="worth_right">
          <img
            src="../../assets/image/worth_right.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <div class="cooperation_wrap">
      <h2>合作案例</h2>
      <ul class="cooperation_case">
        <li class="case_unit">
          <img
            src="../../assets/image/cooperation01.png"
            alt=""
          >
          <p>合作一</p>
        </li>
        <li class="case_unit">
          <img
            src="../../assets/image/cooperation02.png"
            alt=""
          >
          <p>合作二</p>
        </li>
        <li class="case_unit">
          <img
            src="../../assets/image/cooperation01.png"
            alt=""
          >
          <p>合作三</p>
        </li>
        <li class="case_unit">
          <img
            src="../../assets/image/cooperation02.png"
            alt=""
          >
          <p>合作四</p>
        </li>
      </ul>
    </div>
    <div class="news_wrap">
      <h2>新闻动态，了解从现在开始</h2>
      <ul class="news_ul">
        <li
          class="news_li"
          v-for="item in newsInformation"
          :key="item.index"
        >
          <div class="news_left">
            <p class="news_information">新闻动态</p>
            <p class="news_time">{{item.newTime}}</p>
          </div>
          <div class="news_right">
            <p
              class="news_title"
              :title="item.newTitle"
            >{{item.newTitle}}</p>
            <p
              class="news_text"
              :title="item.newText"
            >{{item.newText}}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentIndex: 1,
      promotionList: [
        {
          icon: require("../../assets/image/promotion_icon1.png"),
          title: "预约点餐"
        },
        {
          icon: require("../../assets/image/promotion_icon2.png"),
          title: "菜谱计划"
        },
        {
          icon: require("../../assets/image/promotion_icon3.png"),
          title: "桌面点餐端"
        },
        {
          icon: require("../../assets/image/promotion_icon4.png"),
          title: "饭卡支付"
        },
        {
          icon: require("../../assets/image/promotion_icon5.png"),
          title: "智能表单"
        },
        {
          icon: require("../../assets/image/promotion_icon6.png"),
          title: "统计汇总"
        },
        {
          icon: require("../../assets/image/promotion_icon7.png"),
          title: "会员储值"
        },
        {
          icon: require("../../assets/image/promotion_icon8.png"),
          title: "小票打印"
        },
        {
          icon: require("../../assets/image/promotion_icon9.png"),
          title: "财务结算"
        },
        {
          icon: require("../../assets/image/promotion_icon10.png"),
          title: "大数据统计"
        }
      ],
      enterpriseList: [
        {
          serviceIcon: require("../../assets/image/enterprise_icon01.png"),
          title: "就餐多选择",
          text1: "可线上点餐可堂食自助点餐"
        },
        {
          serviceIcon: require("../../assets/image/enterprise_icon02.png"),
          title: "消费更透明",
          text1: "余额饭卡在线充值消费查询"
        },
        {
          serviceIcon: require("../../assets/image/enterprise_icon03.png"),
          title: "工作更效率",
          text1: "减少排队降低人工成本"
        }
      ],
      developList: [
        {
          serviceIcon: require("../../assets/image/develop_icon01.png"),
          title: "丰富的开发经验",
          text: "10年专注于电商软件的研发，为您提供专业的服务和高效的解决方案"
        },
        {
          serviceIcon: require("../../assets/image/develop_icon02.png"),
          title: "开发时限有保障",
          text:
            "根据客户提供的功能需求，综合评估开发周期，保障客户项目进展高效、可控"
        },
        {
          serviceIcon: require("../../assets/image/develop_icon03.png"),
          title: "实时响应效率高",
          text: "一对一专属定制客服，随时随地为您的电子商务平台保驾护航"
        },
        {
          serviceIcon: require("../../assets/image/develop_icon04.png"),
          title: "自主性更强",
          text: "100%源代码支持，无任何源码加密，全面满足企业个性化需求"
        }
      ],
      newsInformation: [
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        },
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        },
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        },
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        },
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        },
        {
          newTime: "06/07",
          newTitle: "这是一条新闻动态标题",
          newText: "这是一条新闻动态内容…"
        }
      ]
    };
  },
  created() {},
  methods: {
    change: function(index) {
      this.currentIndex = index;
    }
  }
};
</script>
<style >
@import "./appMall.css";
</style>
<style scoped>
.banner {
  width: 100%;
  height: 540px;
}
.banner .ivu-carousel {
  width: 100%;
  height: 100%;
}
.banner .demo-carousel {
  width: 100%;
  height: 540px;
  background: url(../../assets/image/banner_bg.png);
}
.banner .demo-carousel-item {
  width: 1200px;
  height: 540px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.carousel_content {
  text-align: left;
}
.banner .demo-carousel-item h2 {
  font-size: 50px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
  margin-bottom: 10px;
}
.banner .demo-carousel-item p {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 33px;
  margin-bottom: 40px;
}
.banner .demo-carousel-item button {
  width: 185px;
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  border: none;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ff0610;
  line-height: 55px;
}
.banner .demo-img {
  width: 668px;
  height: auto;
}

.promotion_wrap {
  margin: auto;
  background: #f0f4fc;
  padding-top: 40px;
}
.promotion_wrap h3 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 0 0 30px 0px;
}
.promotion_list {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
.promotion_list li {
  width: 224px;
  margin-right: 20px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}
.promotion_list li:nth-child(5n) {
  margin-right: 0px;
}
.promotion_list_con .promotion_list_imgbox {
  width: 100px;
  height: 100px;
}
.promotion_list_con .promotion_list_imgbox img {
  width: 100%;
  height: 100%;
}
.promotion_list_con h4 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 12px 0;
}
.promotion_list_con p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4a4a4a;
}
.promotion button {
  width: 130px;
  height: 44px;
  background: #ff0610;
  border-radius: 2px;
  color: #fff;
  border: 0;
}
.promotion {
  margin-top: 20px;
  margin-bottom: 70px;
}
.case_wrap {
  width: 1200px;
  margin: auto;
}
.case_con {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}
.case_con .case_con_img1 {
  width: 292px;
  height: 490px;
  margin-right: 18px;
  display: flex;
  align-items: center;
}
.case_con .case_con_img1 img {
  width: 100%;
  /* height: 100%; */
}
.case_con_info {
  width: 436px;
  border-radius: 2px;
  box-sizing: border-box;
  /* padding: 0 60px 0 50px; */
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.case_con_info h4 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.case_con_info p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 21px;
  margin: 20px 0 0 0;
}
.support_con {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
}
.support_con_img {
  height: 360px;
  margin-right: 18px;
  display: flex;
  align-items: center;
}
.support_con_info p {
  width: 436px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
  line-height: 21px;
  margin: 20px 0 0 0;
  text-align: left;
}
.backstage {
  padding-bottom: 60px;
}
.advantage_wrap {
  background: #fafafa;
  height: 110px;
}
.enterprise_con_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 110px;
  width: 1200px;
  margin: 0 auto;
}
.enterprise_con_li {
  width: 390px;
  display: flex;
  align-items: center;
}

.con_li_img {
  width: 46px;
  height: 46px;
  overflow: hidden;
  margin-right: 15px;
}
.con_li_img img {
  width: 100%;
  height: 100%;
}
.con_li_content {
  text-align: left;
}
.con_li_title {
  height: 21px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #ff0610;
  line-height: 21px;
}
.con_li_text1 {
  height: 21px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  line-height: 21px;
  margin-top: 8px;
}
.support_wrap {
  background: #f0f4fc;
  padding-top: 40px;
}
.support_wrap h2 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.support_header_nav {
  padding-top: 60px;
}
.support_header_nav h2 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.support_header_nav ul {
  width: 1200px;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto 40px;
}
.support_header_nav ul li {
  width: 200px;
  line-height: 40px;
  background: transparent;
  border-radius: 20px;
  cursor: pointer;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}
.support_header_nav ul .active {
  color: #fff;
  background: #ff0610;
}
.enterprise_con_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.enterprise_con_li {
  width: 390px;
  display: flex;
  border-radius: 5px;
  padding: 20px;
  align-items: center;
}

.develop_li_content {
  text-align: center;
}
.develop_li_title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #495057;
  line-height: 21px;
  margin: 8px 0;
}
.develop_li_text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #868e96;
  line-height: 21px;
}
.support_con_ul {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}
.develop_con_li {
  width: 250px;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.process_wrap {
  padding-top: 60px;
}
.process_wrap h2 {
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 50px;
}
.process_img {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-block: 60px;
}
</style>